html {
  scroll-behavior: smooth;
}
html, body {
  scroll-padding-top: 56px; /* height of sticky header */
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* makes subnavbars opaque and visible over other content when they stick to the top of the page */
.subnavbar {
  background: white;
  padding: 0.5em 0;
  margin-bottom: 0.5em;
  z-index: 500;
}

/* make the TPR sidebar appear underneath the lightbox */
.tpr-sidebar {
  z-index: 500;
}
.tpr-sidebar .list-group-item { cursor: pointer; }

/* attribute lists (i.e., the user info page) have the following format:
<div className='attribute-list'>
  <div (row)><b (label)/><span (value) /></div (row)></div>
(it's not beautiful, but it works for now...)
 */
.attribute-list > div {
  display: flex;
  margin-bottom: 0.25em;
}
.attribute-list > div > b {
  min-width: 100px;
  text-align: right;
  margin-right: 1ex;
}
.attribute-list .roles-list {
  padding-left: 25px;
}
.attribute-list .roles-list li {
  padding-left: 0;
}

/*
----------------------------------------------------------------------------------------
--- figure styles
----------------------------------------------------------------------------------------
*/
.lightbox { z-index: 5000; }
/* FIXME: lightbox images are stretched horizontally if vw > vh, see if there's something we can do */

/*
----------------------------------------------------------------------------------------
--- summary, conclusion, MSR, treatment table styles
----------------------------------------------------------------------------------------
*/

.msr-error-report {
  overflow: scroll;
  resize: vertical;
  height: 6em;
  width: 100%;
  border: solid 1px #ccc;
  padding: 5px;
  border-radius: 3px;
}

/* override react-rte's serif'd fonts for the document default */
.rte-summary {
  font-family: inherit;
}

/* r&d support badges need some internal margin, since they nest badges */
.rnd-badge .badge .badge {
  margin-left: 2px;
  margin-right: 2px;
}
.badge.supports {
  background-color: #1ecde9; color: white;
}
.badge.lacks {
  background-color: #5c5c5c; color: white;
}
.badge.counterindicated {
  background-color: #b8493e; color: white;
}
/* editable badges */
.badge.candidate {
  background: white; border: dotted 1px #555; color: #555;
}
.badge.selectable {
  cursor: pointer;
}
.badge.selected {
  border: solid 1px #aaa;
  box-shadow: 0 0 0 3px rgba(0, 128, 255, 0.5);
}

.subclones-tbl .subclone-idx, .subclones-tbl .subclone-selector {
  min-width: 2em;
  text-align: center;
}
